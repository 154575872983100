import { snakeCase } from 'lodash';

import { ISalesOrder } from 'models/ISalesOrder';
import { IShoppingCartItem } from 'models/IShoppingCartItem';

import processGoogleTag from './processGoogleTag';

enum ShoppingCartItemTypes {
  TicketBook = 'Ticket Book',
  Donation = 'Donation',
  Subscription = 'Subscription',
}

const SITE_NAME = 'surflottery';

const getAmountToPayFromShoppingCartItem = (
  shoppingCartItem: IShoppingCartItem,
) => {
  return (
    (shoppingCartItem.champion_club_plan?.dollar_value ||
      shoppingCartItem.ticket_book?.ticket_book_dollar_amount ||
      shoppingCartItem.donation_option?.amount ||
      shoppingCartItem.custom_unit_amount ||
      0) * shoppingCartItem.quantity
  );
};

const getCategoryByShoppingCartItem = (
  shoppingCartItem: IShoppingCartItem,
): ShoppingCartItemTypes | undefined => {
  if (shoppingCartItem.ticket_book_id) {
    return ShoppingCartItemTypes.TicketBook;
  }
  if (
    shoppingCartItem.donation_option_id ||
    shoppingCartItem.donation_campaign
  ) {
    return ShoppingCartItemTypes.Donation;
  }
  if (shoppingCartItem.champion_club_plan_id) {
    return ShoppingCartItemTypes.Subscription;
  }
  console.error('[getCategoryByShoppingCarItem]', shoppingCartItem);
  return undefined;
};

const getProductCode = (
  shoppingCartItem: IShoppingCartItem,
  category?: ShoppingCartItemTypes,
) => {
  if (category === ShoppingCartItemTypes.Donation) {
    return shoppingCartItem.donation_option?.donation_campaign?.product_code;
  }
  if (category === ShoppingCartItemTypes.Subscription) {
    return shoppingCartItem.champion_club_plan?.product_code;
  }
  return shoppingCartItem.ticket_book?.ticket_book_type?.product_code;
};

const processSalesOrderWithGoogleTag = (salesOrder: ISalesOrder) => {
  if (
    !salesOrder.shopping_cart_items ||
    salesOrder.shopping_cart_items?.length === 0
  ) {
    return;
  }

  processGoogleTag({ event: 'purchase', ecommerce: null });

  const subscriptionCartItems = salesOrder.shopping_cart_items.filter(
    i =>
      getCategoryByShoppingCartItem(i) === ShoppingCartItemTypes.Subscription,
  );

  const ticketCartItems = salesOrder.shopping_cart_items.filter(
    i => getCategoryByShoppingCartItem(i) === ShoppingCartItemTypes.TicketBook,
  );

  if (subscriptionCartItems.length > 0) {
    processGoogleTag({
      event: 'purchase',
      site: SITE_NAME,
      lottery_subscription_plan_id:
        subscriptionCartItems[0].champion_club_plan_id,
      donor_type: 'returning',
      donation_type: 'lottery_champion',
      ecommerce: {
        transaction_id: salesOrder.id,
        value: subscriptionCartItems.reduce((acc, shoppingCartItem) => {
          return acc + getAmountToPayFromShoppingCartItem(shoppingCartItem);
        }, 0),
        currency: 'AUD',
        email: salesOrder.customer.email,
        items: subscriptionCartItems.map(shoppingCartItem => {
          const price = getAmountToPayFromShoppingCartItem(shoppingCartItem);
          const frequency = snakeCase(
            shoppingCartItem.frequency?.name,
          ).toLowerCase();

          return {
            item_id: getProductCode(shoppingCartItem),
            item_name: shoppingCartItem.champion_club_plan?.plan_title,
            affiliation: SITE_NAME,
            commence_now: shoppingCartItem.cc_commence_now_ind,
            item_category: 'lottery_champion',
            item_category2: frequency,
            item_category3: 'champion',
            price,
            quantity: shoppingCartItem.quantity,
          };
        }),
      },
    });
  }

  if (ticketCartItems.length > 0) {
    processGoogleTag({
      event: 'purchase',
      site: SITE_NAME,
      donor_type: 'new',
      donation_type: 'lottery_once_off',
      ecommerce: {
        transaction_id: salesOrder.id,
        value: ticketCartItems.reduce((acc, shoppingCartItem) => {
          return acc + getAmountToPayFromShoppingCartItem(shoppingCartItem);
        }, 0),
        currency: 'AUD',
        email: salesOrder.customer.email,
        items: ticketCartItems.map(shoppingCartItem => {
          const price = getAmountToPayFromShoppingCartItem(shoppingCartItem);
          const frequency = snakeCase(
            shoppingCartItem.frequency?.name,
          ).toLowerCase();
          const donationType = snakeCase(`lottery_${frequency}`).toLowerCase();

          return {
            item_id: getProductCode(shoppingCartItem),
            item_name: shoppingCartItem.ticket_book?.ticket_book_type?.summary,
            affiliation: SITE_NAME,
            commence_now: shoppingCartItem.cc_commence_now_ind,
            item_category: donationType,
            item_category2: frequency,
            item_category3: `Draw ${shoppingCartItem.ticket_book?.draw_id}`,
            price,
            quantity: shoppingCartItem.quantity,
          };
        }),
      },
    });
  }
};
export default processSalesOrderWithGoogleTag;
